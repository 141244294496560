<template>
  <v-app style="font-family: Raleway, Roboto, Arial, Helvetica, sans-serif; color: #000000de;">
    <v-container class="error-page fill-height" tag="section">
      <v-row class="text-center" justify="center">
        <v-col cols="auto">
          <div class="display-4 mb-5 mt-10">
            500
          </div>
          <div style="width: 550px;">
            <div>Internal Server Error</div>
            <div class="mt-5">The server encountered something unexpected that didn't allow it to complete the request. We apologize. You can go back go Home page.</div>
          </div>
          <v-btn class="mt-6" color="info" depressed href="https://planbook.com">
            {{ $t('homeLabel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'PagesError'
};
</script>

<style lang="sass">
.error-page
  h1
    font-size: 12rem
</style>
