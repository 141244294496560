
import Confirm from '@/components/core/Confirm.vue';
import WelcomeMixin from '@/mixins/welcome-mixin';
import CommonServices from '@/services/common-services';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

declare const cmnCookie: any;

@Component({
  mixins: [WelcomeMixin]
})
export default class Login extends Vue {
  email = '';
  password = '';
  valid = false;
  resetPasswordEmail = '';
  promoCode!: string;
  confirmPassword = '';
  errorMsg!: string;
  infoMsg!: string;
  loading!: boolean;
  joinData!: any;
  joinCode!: string;
  redirected!: boolean;
  joinTokenData!: any;
  localStudentJoinData!: any;
  accountExists!: boolean;
  hasJoinCode!: boolean;
  isValidStudentJoinCode!: boolean;
  allowNewStudentAccounts!: boolean;
  studentJoinCode!: string;
  requiredRule!: Array<any>;
  hasErrorMsg!: boolean;
  hasInfoMsg!: boolean;
  hasStudentJoinCode!: boolean;
  teacherId!: any;
  yearId!: any;
  schoolId!: any;
  subjectIds!: Array<any>;

  doReset!: () => void;
  loadJoinData!: () => Promise<any>;
  signIn!: (user: any) => Promise<any>;
  fru!: (condition: boolean, value: any) => any;

  $refs!: {
    confirm: Confirm,
    password: Vue,
    username: Vue,
    loginForm: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  get redirectPath() {
    return (this.$route.query || {}).redirect || '';
  }

  get showSignUpButton() {
    return CommonUtils.hasNoText(this.studentJoinCode) || (this.isValidStudentJoinCode && this.allowNewStudentAccounts);
  }

  redirectToSignUp() {
    this.redirected = true;
    this.$router.push({ path: '/auth/signup' })
  }

  doSignIn() {
    const form: any = this.$refs.loginForm;
    this.valid = true;
    if (form.validate()) {
      this.loading = true;
      const params = {
        email: this.email,
        password: this.password,
        code: this.fru(this.allowNewStudentAccounts, this.email),
        registerStudent: this.fru(this.hasStudentJoinCode, true),
        connect: this.fru(this.accountExists, true),
        token: this.fru(this.hasJoinCode, this.joinCode),
        schoolId: this.schoolId,
        subjectIds: this.subjectIds,
        yearId: this.yearId,
        teacherId: this.teacherId
      };
      this.signIn(params)
        .then(resp => {
          cmnCookie.set('lastLoggedInUserName', this.email);
          return CommonServices.initSession({ settings: resp.data });
        }).then(() => {
          this.$store.commit('settings/setInitialSignIn', true);
          if (CommonUtils.hasText(this.redirectPath)) {
            this.$router.replace(this.redirectPath as string);
          } else {
            this.$router.replace('/plans');
          }
        }).catch((e: Error) => {
          this.loading = false;
          this.errorMsg = e.message;
        });
    }
  }

  get animationStartListener() {
    return (e: AnimationEvent) => {
      switch (e.animationName) {
        case 'onAutoFillStart':
          return this.onAutoFillStart();

        case 'onAutoFillCancel':
          return this.onAutoFillCancel();
      }
    }
  }

  mounted() {
    try {
      const usernameField = this.$refs.username.$el.querySelector('input') as HTMLElement;
      const passwordField = this.$refs.password.$el.querySelector('input[type="password"]') as HTMLElement;
      usernameField.setAttribute('name', 'username');
      passwordField.setAttribute('name', 'password');
      usernameField.setAttribute('autocomplete', 'username')
      passwordField.setAttribute('autocomplete', 'current-password');
      if (CommonUtils.hasText(this.email)) {
        passwordField.focus();
      } else {
        usernameField.focus();
      }
      passwordField.addEventListener('animationstart', this.animationStartListener)
    } catch (e) {
    }
  }

  beforeDestroy() {
    try {
      const passwordField = this.$refs.password.$el.querySelector('input[type="password"]') as HTMLElement;
      passwordField.removeEventListener('animationstart', this.animationStartListener);
    } catch (e) {
    }
  }

  onAutoFillStart() {
    try {
      const usernameField = this.$refs.username.$el.querySelector('input') as HTMLElement;
      const passwordField = this.$refs.password.$el.querySelector('input[type="password"]') as HTMLElement;
      usernameField.blur();
      passwordField.blur();
    } catch (e) {
    }
    this.doSignIn();
  }

  onAutoFillCancel() {
    console.log('Password Auto-Fill Cancelled.');
  }

  created() {
    this.infoMsg = '';
    this.errorMsg = '';
    this.redirected = false;

    this.email = cmnCookie.get('lastLoggedInUserName') || '';
    if (CommonUtils.hasText(this.joinCode)) {
      if (CommonUtils.hasText(this.joinData.emailAddress)) {
        this.email = this.joinData.emailAddress;
      }
      this.infoMsg = this.$t('welcomeMsg100', {
        name: this.joinData.schoolName,
        action: (this.$t('signInLabel') as string).toLowerCase()
      }) as string;
    } else if (CommonUtils.hasText(this.studentJoinCode)) {
      this.infoMsg = this.$t('welcomeMsg101', {
        codes: this.localStudentJoinData.subjectIds.join(', '),
        action: (this.$t('signInLabel') as string).toLowerCase()
      }) as string;
    }
  }
}
